.questionContainer{
  top:250px;
  position: absolute;

}

.title{
  top: 5px;
  left: 5px;
  position: absolute;
  align-content: center;
  background-color: white;
  z-index: 1;
}

.backHomeButton{
  bottom: 20px;
  left: 20px;
  position: absolute;
  margin: 0 10px 0 10px;
  border: 10px solid rgb(126, 216, 255);
  border-radius: 2px;
  background-color: azure;
  width: 500px;
  height: 150px;
  text-align: center;
  vertical-align: middle;
  line-height: 150px;
}

.backHomeButtonText{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.prevQuestionBtn{
  margin: 0 20px 0 20px;
  width: 400px;
  height: 200px;
  border: none;
  border-radius: 10px;
  font-size: 40px;
  background-color: lightpink;
}
.prevQuestionBtn:disabled{
  background-color: lightgray;
}

.nextQuestionBtn{
  margin: 0 20px 0 20px;
  width: 400px;
  height: 200px;
  border: none;
  border-radius: 10px;
  font-size: 40px;
  background-color: lightgoldenrodyellow;
}
.nextQuestionBtn:disabled{
  background-color: lightgray;
}

.reviseAnswerBtn{
  margin: 0 20px 0 20px;
  width: 400px;
  height: 200px;
  border: none;
  border-radius: 10px;
  font-size: 40px;
  background-color: lightgreen;
}
.reviseAnswerBtn:disabled{
  background-color: lightgray;
}
