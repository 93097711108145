/* 
  Dirty way to make styling for keyboard
  TODO: module css
*/

.simple-keyboard.myTheme1 {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.simple-keyboard.myTheme1 .hg-button {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.0);
  color: white;
}

.simple-keyboard.myTheme1 .hg-button:active {
  background: #1c4995;
  color: white;
}
