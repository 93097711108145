.everythingContainer {
  /* ¿still need this style? */
  width: 100vw;
  height: 100vh;
  /* width: 1080px;
  height: 1920px; */
  overflow: hidden;
}

.header {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: bisque;
}

.unityContainer {
  z-index: -1;
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100vw;
  background-color: skyblue;
}

.pageContainer {
  height: 1200px;
  width: 1080;
  /* background-color: rgba(0, 0, 255, 0.5); */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.speechBubbleContainer {
  top: 900px;
  left: 400px;
  height: 400px;
  width: 500px;
  /* background-color: rgba(0, 255, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.testButton{
  position: absolute;
  top: 5px;
  left: 5px;
  width: 70vw;
  height: 10vh;
  z-index: 2;
  background-color: bisque;
  border: 1px;
}

.testDiv{
  height: "100%";
  width: "100%";
  background-color: aqua;
  color: aqua;
  border: 1px;
}
