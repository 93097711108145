.statusContainer{
  top: 400px;
  display: flex;
  position: absolute;
}

.Box{
  width: 450px;
  height: 450px;
  text-align: center;
  vertical-align: middle;
  margin: 20px;
  border-radius: 20px;
}

.waitingBox{
  background-color: slategray;
}

.passBox{
  background-color: lightgreen;
}

.failBox{
  background-color: lightsalmon;
}

.faIcon{
  top: 10px;
  font-size: 250px;
  position: relative;
}

.backHomeButton{
  bottom: 20px;
  left: 20px;
  position: absolute;
  margin: 0 10px 0 10px;
  border: 10px solid rgb(126, 216, 255);
  border-radius: 2px;
  background-color: azure;
  width: 500px;
  height: 150px;
  text-align: center;
  vertical-align: middle;
  line-height: 150px;
}

.backHomeButtonText{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
