.headerContainer {
  height: 100px;
  display: flex;
  /* background-color: bisque; */
}

.leftLogo {
  top: 20px;
  left: 20px;
  width: auto;
  height: 100px;
  position: absolute;
}
.rightLogo {
  top: 20px;
  right: 20px;
  width: auto;
  height: 100px;
  position: absolute;
}