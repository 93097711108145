.inputContainer {
  /* border: 2px solid black; */
  /* width: 200px;
  height: 100px; */
  display: flex;
}

/* Hide the browser's default radio button */
.inputContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: inherit;
  top: 0;
  left: 0;
  height: 90px;
  width: 90px;
  background-color: #eee;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.inputContainer input:checked ~ .checkmark {
  background-color: #eee;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: relative;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.inputContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.inputContainer .checkmark:after {
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #28F;
}

.questionText {
  top: 5px;
  left: 20px;
  position: relative;
}