.inputBox {
  border: 10px solid rgb(126, 216, 255);
  border-radius: 5px;
  padding: 10px;
  width: 700px;
  height: 1em;
  font-size: 2em;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  overflow: hidden;
}

.barcodeReading{
  top: 100px;
  left: 5px;
  position: absolute;
  align-content: center;
  background-color: lightpink;
  z-index: 1;
}

.keyboardContainer{
  display: flex;
  justify-content: center;
  width: 1024px;
  margin: 0 auto;
  border-radius: 0px;
  top: 500px;
  position: absolute;
}

.buttonContainer{
  top: 450px;
  display: flex;
  position: absolute;
}

.cancelButton{
  width: 400px;
  height: 260px;
  background-color: lightsalmon;
  margin: 20px;
  border-radius: 20px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmButton{
  width: 400px;
  height: 260px;
  background-color: lightgreen;
  margin: 20px;
  border-radius: 20px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonText{
  font-size: 75px;
}

.backHomeButton{
  bottom: 20px;
  left: 20px;
  position: absolute;
  margin: 0 10px 0 10px;
  border: 10px solid rgb(126, 216, 255);
  border-radius: 2px;
  background-color: azure;
  width: 500px;
  height: 150px;
  text-align: center;
  vertical-align: middle;
  line-height: 150px;
}

.backHomeButtonText{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
