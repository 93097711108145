.cardsContainer {
  /* border-top: 5px solid red;
  border-bottom: 5px solid red; */
  position: relative;
  top: 100px;
  left: 0px;
  height: 900px;
  width: 100vw;
  display: flex;
  overflow-x: auto;
  /* flex-wrap: nowrap; */
  align-items: center;
}

.cardsContainer::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.i18nButtonContainer {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
}

.i18nButtons {
  margin: 0 10px 0 10px;
  border: 10px solid rgb(126, 216, 255);
  border-radius: 2px;
  background-color: azure;
  width: 170px;
  height: 150px;
  font-size: 15px;
}
