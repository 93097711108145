.backHomeButton{
  bottom: 20px;
  left: 20px;
  position: absolute;
  margin: 0 10px 0 10px;
  border: 10px solid rgb(126, 216, 255);
  border-radius: 2px;
  background-color: azure;
  width: 500px;
  height: 150px;
  text-align: center;
  vertical-align: middle;
  line-height: 150px;
}

.backHomeButtonText{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.title{
  top: 500px;
  position: absolute;
  align-content: center;
  font-size: 100px;
  text-align : center;
  /* background-color: white; */
  z-index: 1;
}
