.card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  height: 800px;
  width: 400px;
  margin: 0 40px 0 40px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.posterCard {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  width: 1200px;
  height: 800px;
  margin: 0 40px 0 40px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.poster {
  width: 1200px;
  height: 800px;
  position: absolute; 
}

.mainCard {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  height: 800px;
  width: 600px;
  margin: 0 10px 0 10px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
