.container {
  position: relative;
  background: #00aabb;
  border-radius: .4em;
  font-size: 50px;
  padding: 20px;
}

.container:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #00aabb;
  border-bottom: 0;
  border-right: 0;
  margin-right: -10px;
  margin-bottom: -20px;
}